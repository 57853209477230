.admin-sub-menu {
    background-color: #f1f1f1;
    transition: width 0.5s ease 0s;
    overflow-x: hidden;
    padding: 1rem;
}

.admin-sub-menu .project-name {
    margin-bottom: 0.8rem;
}

.admin-sub-menu .item-render {
    display: contents;
}

.admin-sub-menu .items-container {
    height: calc(100% - 3rem);
    overflow-y: auto;
}

.admin-sub-menu .item-container {
    background: linear-gradient(to left, #ffffff00 50%, #dee2e666 50%) right;
    background-size: 200%;
    transition: .1s ease-out;
    height: 32px;
    width: 100%;
    cursor: pointer;
}

.admin-sub-menu .item-container:hover {
    background-position: left;
}

.admin-sub-menu .current-page {
    background-color: #2196F3;
    color: #fff;
}

.admin-sub-menu .im-make-group-icon {
    height: 20px;
    width: 20px;
}

.admin-sub-menu .im-move-down-icon {
    height: 24px;
    width: 24px;
    margin-bottom: 4px;
    margin-left: 2px;
}

.admin-sub-menu .di-git-icon {
    height: 24px;
    width: 24px;
}

.admin-sub-menu .im-tree-icon {
    height: 18px;
    width: 18px;
}

.admin-sub-menu .ri-dashboard-line-icon {
    height: 24px;
    width: 24px;
}

.admin-sub-menu .si-jupyter-icon {
    height: 22px;
    width: 22px;
}

.admin-sub-menu .bi-brain-icon {
    height: 24px;
    width: 24px;
}

.admin-sub-menu .im-table-icon {
    height: 20px;
    width: 20px;
}

.admin-sub-menu .git-sync-button {
    width: 2rem;
    height: 2rem;
}

.admin-sub-menu .last-bottom-item {
    position: absolute;
    bottom: 0;
}
