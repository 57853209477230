

.task-env-dialog {
    height: unset;
}

.task-env-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.task-env-dialog .select-command {
    width: 136px;
    border-radius: 4px 0px 0px 4px;
}

.task-env-dialog .exec-container {
    width: 100%;
    align-items: flex-start;
}

.task-env-dialog .exec-container .button-test {
    min-width: 86px;
 }
 
 .task-env-dialog .exec-container .button-build-run {
     min-width: 148px;
 }

.task-env-dialog .exec-form {
    width: calc(100% - 223px - 1rem)
}