.dbt-file-sessions-table {
    height: 100%;
}

.dbt-file-sessions-table .card {
    height: 100%;
}

.dbt-file-sessions-table .p-paginator .p-paginator-current {
    margin-left: auto;
}

.dbt-file-sessions-table .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.dbt-file-sessions-table .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.dbt-file-sessions-table .table-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.dbt-file-sessions-table .p-datepicker {
    min-width: 25rem;
}

.dbt-file-sessions-table .p-datepicker td {
    font-weight: 400;
}

.dbt-file-sessions-table .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dbt-file-sessions-table .resource-link {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
    text-decoration: none;
}
.dbt-file-sessions-table .resource-link:hover {
    text-decoration: underline;
}

.dbt-file-sessions-table .customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.dbt-file-sessions-table .status-opened {
    background-color: #c8e6c9;
    color: #256029;
}

.dbt-file-sessions-table .status-closed {
    background-color: #b3e5fc;
    color: #23547b;
}

.dbt-file-sessions-table .status-canceled {
    background-color: #feedaf;
    color: #8a5340;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table {
    border: 1px solid #dee2e6;
    border-radius: 3px;
    height: 100%;
    overflow: auto;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
    border-top: 0px;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-paginator {
    border-bottom: 0px;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-wrapper {
    height: calc(100% - 128px);
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-thead > tr > th {
    text-align: left;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tbody > tr > td {
    cursor: auto;
}

.dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.dbt-file-sessions-table .p-datatable-dbt-file-sessions-table .p-datatable-thead > tr > th > input {
   width: 100%;
}

.dbt-file-sessions-table .p-datatable-dbt-file-sessions-table .p-datatable-thead .p-multiselect {
    width: 100%;
 }

@media screen and (max-width: 960px) {
    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-thead > tr > th,
    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .dbt-file-sessions-table .p-datatable.p-datatable-dbt-file-sessions-table .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}


.dbt-file-sessions-table .dbt-file-sessions-events {
    margin: 2rem -1rem -1rem -1rem;
}