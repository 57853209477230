.model {}

.model .toolbar-left {
    display: flex;
    align-items: center;
}

.model .p-fieldset {
    height: 100%;
    padding: 0.5rem;
}

.model .p-toggleable-content {
    height: 100%;
}

.model .p-fieldset-content {
    height: 100%;
    padding: 0px;
}

.model .p-toolbar {
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
    border: none;
}

.model .di-git-branch-icon {
    height: 24px;
    width: 24px;
}

.model .di-git-merge-icon {
    height: 18px;
    width: 18px;
}

.model .code-editors-container {
    height: calc(100% - 68px);
    display: block;
    position: relative;
    overflow: auto;
}

.model .code-editors-container .expanded-editor {
    width: 70%;
    height: 100%;
    position: absolute;
    left: 30%;
    padding: 0.5rem;
}

.model .code-editors-container .second-editor {
    width: 30%;
    height: 50%;
    position: absolute;
    left: 0rem;
    padding: 0.5rem;
}

.model .code-editors-container .third-editor {
    width: 30%;
    height: 50%;
    position: absolute;
    left: 0rem;
    top: 50%;
    padding: 0.5rem;
}

@media (max-width: 1300px) {
    .model .code-editors-container .expanded-editor {
        width: 100%;
        height: 60%;
        left: 0rem;
    }
    .model .code-editors-container .second-editor {
        top: 60%;
        width: 50%;
        height: 40%;
    }
    .model .code-editors-container .third-editor {
        top: 60%;
        left: 50%;
        width: 50%;
        height: 40%;
    }
}

@media (max-width: 1000px) {
    .model .code-editors-container .expanded-editor {
        width: 100%;
        height: 60%;
        left: 0rem;
    }
    .model .code-editors-container .second-editor {
        top: 60%;
        left: 0%;
        width: 100%;
        height: 40%;
    }
    .model .code-editors-container .third-editor {
        top: 100%;
        left: 0%;
        width: 100%;
    }
}