.put-bundle {
    /*height: fit-content !important;*/
}

.put-bundle .title {
    font-size: x-large;
    font-weight: 400;
    margin: 0.5rem;
}

.put-bundle .container {
    height: calc(100% - 12.3rem);
    width: 100%;
    overflow: auto;
   
}

.put-bundle .container .artefacts-table-container {
   display: flex;
   flex-wrap: wrap;
   height: calc(100% - 2.8rem);
   text-align: center;
}

.put-bundle .container .artefacts-table-container .button-container {
    width: 100%;
    text-align: center;
}

.put-bundle .container .artefacts-table-container .button-container .button-next {
    width: 100%;
    max-width: 620px;
}

.put-bundle .container .artefacts-table-container .available-artefacts-table {
    padding-right: 0.25rem;
    width: 100%;
    max-width: 340px;
    height: 100%;
}

.put-bundle .container .artefacts-table-container .selected-artefacts-table {
    padding-left: 0.25rem;
    width: calc(100% - 340px);
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .put-bundle .container .artefacts-table-container .available-artefacts-table {
        max-width: unset;
        width: 100%;
        padding-right: 0px;
        padding-bottom: 0.5rem;
        height: unset;
    }
    
    .put-bundle .container .artefacts-table-container .selected-artefacts-table {
        width: 100%;
        padding-left: 0px;
        height: unset;
    }
}

.put-bundle .xxl-avatar {
    background-color: #e3f2fd;
    width: 8rem;
    height: 8rem;
}

.put-bundle .xxl-avatar>svg {
    color: #2196F3;
    font-size: 4rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.dialog-input {
    width: 100%;
}

.put-bundle .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    border-radius: 25%;
}


@media screen and (max-width: 640px) {
    .put-bundle .p-steps {
        height: 350px;
    }
    .put-bundle .p-steps > ul {
        flex-direction: column;
        height: 100%;
    }
    .put-bundle .p-steps > ul .p-steps-item {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
    }
    .put-bundle .p-steps > ul .p-steps-item:before {
        position: static;
        left: auto;
        top: auto;
        margin-top: 0;
        border-left: 1px solid var(--surface-d);
        border-top: 0 none;
        width: auto;
        height: 100%;
        margin-left: 1rem;
    }
    .put-bundle .p-steps > ul .p-steps-item .p-menuitem-link {
        flex-direction: row;
        overflow: visible;
    }
    .put-bundle .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
        margin: 0 .5rem 0;
    }
    .put-bundle .p-steps > ul .p-steps-item:last-child {
        flex-grow: 0;
    }
    .put-bundle .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
        padding: 0;
    }
    .put-bundle .p-steps > ul .p-steps-item:last-child:before {
        display: none;
    }
}