.model-tree .p-toolbar {
    margin: -1rem -1rem 1rem -1rem;
    border: none;
}

.model-tree .form-container {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    border-radius: 3px;
}

.model-tree .form-container .model-name-input {
    width: 100%;
}

.model-tree .form-container .buttons-container {
    text-align: right;
}

.model-tree .p-tree {
    height: 100%;
}

.model-tree .p-tree-container {
    height: calc(100% - 68px);
}

.small-model-tree .p-tree {
    height: 100%;
}

.small-model-tree .p-tree-container {
    height: calc(100% - 193px - 1rem);
}