

.de-query-editor {}

.de-query-editor .p-dialog-content {
    display: flex;
}

.de-query-editor .contexts-list {
    width: 300px;
}

.de-query-editor .chat-container {
    width: calc(50% - 1.5rem);
    height: calc(100% - 80px - 2rem);
    position: absolute;
    display: inline-flex;
}

.de-query-editor .open-chat-button {
    height: fit-content;
}

.de-query-editor .chat-container .chat-box {
    width: 100%;
    height: calc(100% - 51px);
    border: 1px solid #dee2e6;
    border-radius: 3px;
    overflow-y: scroll;
}

.de-query-editor .chat-container .inputs-container {
    width: 100%;
    align-items: flex-start;
}

.de-query-editor .chat-container .chat-box ::-webkit-scrollbar-thumb  {
    background-color: #2b2b2b;
    background-clip: padding-box;
}

.de-query-editor .chat-container .chat-box .sql-code-container {
    position: relative;
}

.de-query-editor .chat-container .chat-box .sql-content {
    border-radius: 3px;
}

.de-query-editor .chat-container .chat-box .chat-play-button {
    background-color: unset;
    border-radius: unset;
    color: #2b2b2b;
    background: #dcdcdc;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    position: absolute;
    top: 6px;
    inset-inline-end: 6px;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.de-query-editor .chat-container .chat-box .chat-play-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.de-query-editor .chat-container .input-form {
    width: calc(100% - 167px - 1rem);
}

.de-query-editor .editor-container {
    position: absolute;
    left: 50%;
    width: calc(50% - 1.5rem);
    height: calc(100% - 80px - 2rem);
    display: flex;
    flex-flow: column;
}

.de-query-editor .editor-container-full {
    left: calc(38px + 2.5rem) !important;
    width: calc(100% - 4rem - 38px) !important;
}

.de-query-editor .editor-container .editor {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
}

.de-query-editor .editor-container .text {
    font-style: italic;
}

.de-query-editor .editor-container .text-error {
    color: var(--red-500);
}

.de-query-editor .editor-container .text-error .resource-link {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
}
.de-query-editor .editor-container .text-error .resource-link:hover {
    text-decoration: underline;
}