
.main-menu {
    background-color: #2b2b2b;
    transition: width 0.5s ease 0s;
    overflow-x: hidden;
    padding: 1rem;
    height: 100%;
}
.main-menu .expanded {
    width: calc(244px - 5rem);
    word-break: break-word;
    visibility: visible !important;
}
.main-menu .datatask-logo {
    height: 2.5rem;
    width: 2.5rem;
}
.main-menu .header-item {
    margin-left: -0.25rem;
}
.main-menu .project-container {
    height: calc(100% - 150px);
}
.main-menu .project-container .current-project {
    background-color: #2196F3;
    color: #ffffff;
}
.main-menu .current-page {
    background-color: #2196F3;
    color: #ffffff;
}

.main-menu .button-admin {
    bottom: calc(0.5rem + 32px);
    position: absolute;
}

.main-menu .button-logout {
    bottom: 0.5rem;
    position: absolute;
}

.main-menu .release-version  {
    color: #ffffff;
    text-align: center;
    font-style: italic;
    font-size: 10px;
    margin-top: 6px;
    overflow: hidden;
    white-space: nowrap;
}
.main-menu .release-version-hidden {
    display: none;
}
