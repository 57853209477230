#env-name-value>.p-field {
    margin: 0;
}

#env-name-value>.p-field>div {
    display: flex;
    flex-wrap: wrap;
}

#env-name-value>.p-field>div>.property-wrapper {
    width: 50%;
}

#env-name-value>.p-field>div>.property-wrapper>.p-field {
    padding-right: 0.5rem;
    margin-bottom: 0;
}