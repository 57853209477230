.dbt-test {}

.dbt-test .toolbar-left {
    display: flex;
    align-items: center;
}

.dbt-test .p-fieldset {
    height: 100%;
    padding: 0.5rem;
}

.dbt-test .p-toggleable-content {
    height: 100%;
}

.dbt-test .p-fieldset-content {
    height: 100%;
    padding: 0px;
}

.dbt-test .p-toolbar {
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
    border: none;
}

.dbt-test .code-editors-container {
    height: calc(100% - 68px);
    display: block;
    position: relative;
    overflow: auto;
}

.dbt-test .code-editors-container .editor {
    width: 100%;
    height: 100%;
    position: absolute;
}

.dbt-test .di-git-branch-icon {
    height: 24px;
    width: 24px;
}

.dbt-test .di-git-merge-icon {
    height: 18px;
    width: 18px;
}

.dbt-test .split-button {
    font-size: 1rem;
    padding: none;
}

.job-error-dialog {
    height: unset;
}

.job-error-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.job-error-dialog .command-output {
    border: 1px solid #dee2e6;
    border-radius: 3px;
    display: block;
    padding: 12px;
    min-height: 240px;
    height: calc(100% - 62px);
    overflow: auto;
    background-color: #212121;
    padding: 1.25rem;
    font-family: monospace;
    font-size: 0.9rem;
    font-weight: normal;
    color: #fff;
}
