
.new-release-dialog .external-link {
    cursor: pointer;
    color: #2196f3;
    word-break: break-all;
    width: fit-content;
    text-decoration: none;
}
.new-release-dialog .external-link:hover {
    text-decoration: underline;
}