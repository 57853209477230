.connections-horizontal-inventory {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.connections-horizontal-inventory .content {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    
}

.connections-horizontal-inventory .table-header {
    width: 100%;
}

.connections-horizontal-inventory ::-webkit-scrollbar {
    height: 8px;
    
}

.connections-horizontal-inventory ::-webkit-scrollbar-thumb {
    background-color: #2196F3;
    background-clip: padding-box;
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.connections-horizontal-inventory .connection-list-item {
    display: flex;
    padding: .5rem;
    min-width: 300px;
    margin: .5rem;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    cursor: pointer;
}

.connections-horizontal-inventory .content .connection-list-item:first-child {
    margin-left: auto;
}

.connections-horizontal-inventory .content .connection-list-item:last-child {
    margin-right: auto;
}

.connections-horizontal-inventory .selected-connection {
    background-color: #e3f2fd;
}

.connections-horizontal-inventory .connection-name {
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.connections-horizontal-inventory .connection-description {
    margin: 0 0 1rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2rem;

}

.connections-horizontal-inventory .connection-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.connections-horizontal-inventory .connection-category {
    vertical-align: middle;
}

.connections-horizontal-inventory .connection-list-item img {
    width: 3rem;
    height: 3rem;
    margin: 0 .5rem 0 0;
}

.connections-horizontal-inventory .connection-list-item .connection-list-detail {
    flex: 1 1 0;
}

.connections-horizontal-inventory .connection-list-item .connection-list-action {
    display: flex;
    flex-direction: column;
}

.connections-horizontal-inventory .connection-list-item .p-button {
    margin-bottom: .5rem;
}

@media screen and (max-width: 576px) {
    .connections-horizontal-inventory .connection-list-item {
        flex-direction: column;
        align-items: center;
    }
    .connections-horizontal-inventory .connection-list-item img {
        width: 75%;
        margin: 2rem 0;
    }
    .connections-horizontal-inventory .connection-list-item .connection-list-detail {
        text-align: center;
    }
    .connections-horizontal-inventory .connection-list-item .connection-price {
        align-self: center;
    }
    .connections-horizontal-inventory .connection-list-item .connection-list-action {
        display: flex;
        flex-direction: column;
    }
    .connections-horizontal-inventory .connection-list-item .connection-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}