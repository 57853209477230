.data-explorer {
    display: flex;
}

.shareable-link-input {
    margin-left: -1rem !important;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.shareable-link-button {
    margin-top: -1rem !important;
}

.data-explorer .table-menu {
    width: 220px;
    background-color: #fbfbfb;
    margin: -0.5rem 0px -0.5rem -0.5rem;
    overflow: auto;
}

.data-explorer .select-connection {
    padding: 1rem 0.2rem 1rem 1rem;
}

.data-explorer .select-connection .connections-list {
    width: 100%;
}

.data-explorer .table-title {
    padding: 0rem 1rem 0rem 1rem;
    margin-bottom: 0px;
}

.data-explorer .table-tree-min-height {
    border: none;
    background: none;
    padding: 1rem 0.2rem 1rem 1rem;
    height: calc(100% - 466px);
}

.data-explorer .table-tree-full-height {
    border: none;
    background: none;
    padding: 1rem 0.2rem 1rem 1rem;
    height: calc(100% - 198px);
}


.data-explorer .table-info {
    padding: 2rem 0.2rem 1rem 1rem;
}

.data-explorer .table-info .progress-spinner-metadata-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
}

.data-explorer .table-info .progress-spinner-metadata-container .p-progress-spinner-circle {
    stroke: #495057 !important;
}

.data-explorer .table-info .progress-spinner-proportion-container {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
}

.data-explorer .table-info .progress-spinner-proportion-container .p-progress-spinner-circle {
    stroke: #495057 !important;
}

.data-explorer .table-info .input-proportion {
    width: 100%;
}

.data-explorer .table-info .input-proportion>input {
    width: 100%;
}

.data-explorer .table-info .slider-proportion .p-slider-handle {
    z-index: 0 !important;
}

.data-explorer .table-info .text-max-size {
    font-size: 0.8rem;
}

.data-explorer .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: none;
}

.data-explorer .p-tree .p-tree-container {
    height: 100%;
    overflow-y: auto;
    word-break: break-word;
}

.data-explorer .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    width: fit-content;
    min-width: 100%;
}

.data-explorer .p-tree .p-tree-container .p-treenode .p-treenode-content {
    width: fit-content;
    min-width: 100%;
}

.data-explorer .p-tree .p-treenode-children {
    padding: 0;
}


/*
.data-explorer .p-treenode-leaf>.p-treenode-content .p-tree-toggler {
    display: none;
}*/

.data-explorer .table-container .avatar-button {
    background-color: unset;
    border-radius: unset;
}

.data-explorer .table-container .avatar-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.data-explorer .table-container .avatar-button:hover {
    background-color: #dee2e6;
}

.data-explorer .table-container .table-input {
    width: calc(100% - 1.5rem);
}

.data-explorer .table {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 1.5rem);
}

.data-explorer .selected-table {
    background-color: #e3f2fd;
}

.data-explorer .data-table-container {
    display: flex;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    margin-left: 0.5rem;
}

.data-explorer .data-table {
    /*border: 1px solid #dee2e6;
    border-radius: 3px;*/
    border-left: 0px;
    font-size: small;
    width: 100%;
    /*overflow: auto;*/
}

.data-explorer .data-table-with-form {
    width: calc(100% - 300px);
    border-left: 1px solid #dee2e6;
    transition: width 0.5s ease 0s;
}

.data-explorer .data-table-with-collapsed-form {
    width: calc(100% - 32px - 2rem);
    border-left: 1px solid #dee2e6;
}

.data-explorer .normal-mode {
    width: calc(100% - 220px);
    height: 100%;
    /*border-left: none;*/
    /*overflow: auto;*/
}

.data-explorer .fullscreen-mode {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    position: absolute;
    left: 0;
    background: #fff;
    /*overflow: auto;*/
}

.data-explorer .data-table .active-column-filter .p-column-header-content .p-column-filter .p-column-filter-menu-button {
    background: #E3F2FD;
    color: #495057;
}

.data-explorer .data-table .table-header {
    display: flex;
    justify-content: space-between;
}

.data-explorer .data-table .p-datatable-header {
    border: none;
}

.data-explorer .data-table .p-paginator {
    border: none;
}

.data-explorer .data-table .p-datatable-wrapper {
    height: calc(100% - 128px);
}

.data-explorer .data-table .table-header .navigation {
    word-break: break-all;
    width: calc(100% - 380px);
}

.data-explorer .data-table .table-header .button-back {
    cursor: pointer;
    background-color: unset;
    border-radius: unset;
}

.data-explorer .data-table .table-header .button-back:hover  {
    background-color: #dee2e6;
}

.data-explorer .data-table .type-icon {
    background-color: unset;
    border-radius: unset;
}

.data-explorer .data-table .avatar-button {
    background-color: unset;
    border-radius: unset;
}

.data-explorer .data-table .avatar-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.data-explorer .data-table .object-name {
    word-break: break-all;
}

.data-explorer .data-table .avatar-button:hover {
    background-color: #dee2e6;
}

.data-explorer .data-table .p-datatable-resizable .p-datatable-thead>tr>th, .p-datatable-resizable .p-datatable-tfoot>tr>td, .p-datatable-resizable .p-datatable-tbody>tr>td {
    padding: 0.5rem;
}

