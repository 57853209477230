.connections-inventory {
    width: 100%;
    max-width: 300px;
}

.connections-inventory .table-header {
    width: 100%;
}

.connections-inventory .header-input {
    width: 100%
}

.connections-inventory .header-button {
    width: 118px;
}

.connections-inventory ::-webkit-scrollbar {
    width: 8px;
}

.connections-inventory ::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-color: #2196F3;
    background-clip: padding-box;
}

.connections-inventory .dataview {
    height: calc(100% - 36px - 0.5rem);
}

.connections-inventory .dataview>.p-dataview-content {
    overflow-y: auto;
    height: 100%;
    direction: rtl;
}

.connections-inventory .dataview>.p-dataview-content>.p-grid {
    direction: ltr;
    height: fit-content;
}

.connections-inventory .dataview>.p-dataview-content>.p-grid>div {
    border: none;
}

.connections-inventory .selected-connection {
    background-color: #e3f2fd;
}

.connections-inventory .connection-name {
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.connections-inventory .connection-description {
    margin: 0 0 1rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2rem;

}

.connections-inventory .connection-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.connections-inventory .connection-category {
    vertical-align: middle;
}

.connections-inventory .connection-list-item {
    display: flex;
    padding: .5rem;
    width: 100%;
    margin-bottom: .5rem;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    cursor: pointer;
}

.connections-inventory .dataview>.p-dataview-content>.p-grid>div:last-child>div {
    margin-bottom: 0px;
}

.connections-inventory .connection-list-item :last-child {
    margin-bottom: 0px;
}

.connections-inventory .connection-list-item img {
    width: 3rem;
    height: 3rem;
    margin: 0 .5rem 0 0;
}

.connections-inventory .connection-list-item .connection-list-detail {
    flex: 1 1 0;
}

.connections-inventory .connection-list-item .connection-list-action {
    display: flex;
    flex-direction: column;
}

.connections-inventory .connection-list-item .p-button {
    margin-bottom: .5rem;
}

@media screen and (max-width: 576px) {
    .connections-inventory .connection-list-item {
        flex-direction: column;
        align-items: center;
    }
    .connections-inventory .connection-list-item img {
        width: 75%;
        margin: 2rem 0;
    }
    .connections-inventory .connection-list-item .connection-list-detail {
        text-align: center;
    }
    .connections-inventory .connection-list-item .connection-price {
        align-self: center;
    }
    .connections-inventory .connection-list-item .connection-list-action {
        display: flex;
        flex-direction: column;
    }
    .connections-inventory .connection-list-item .connection-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}