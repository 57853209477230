.object-storage {
    display: flex;
}

.object-storage .bucket-menu {
    width: 220px;
    background-color: #fbfbfb;
    margin: -0.5rem 0px -0.5rem -0.5rem;
    overflow: auto;
}

.object-storage .bucket-title {
    padding: 1rem 1rem 0rem 1rem;
    margin-bottom: 0.8rem;
}

.object-storage .bucket-container {
    height: 32px;
    width: 100%;
    padding: 0px 1rem;
    cursor: pointer;
    justify-content: space-between;
}

.object-storage .bucket-container .avatar-button {
    background-color: unset;
    border-radius: unset;
}

.object-storage .bucket-container .avatar-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.object-storage .bucket-container .avatar-button:hover {
    background-color: #dee2e6;
}

.object-storage .bucket-container .bucket-input {
    width: calc(100% - 1.5rem);
}

.object-storage .bucket {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 1.5rem);
}

.object-storage .selected-bucket {
    background-color: #e3f2fd;
}

.object-storage .object-table {
    width: calc(100% - 220px);
    border: 1px solid #dee2e6;
    border-radius: 3px;
    height: 100%;
    overflow: auto;
}

.object-storage .object-table .table-header {
    display: flex;
    justify-content: space-between;
}

.object-storage .object-table .p-datatable-header {
    border-top: 0px;
}

.object-storage .object-table .p-paginator {
    border-bottom: 0px;
}

.object-storage .object-table .p-datatable-wrapper {
    height: calc(100% - 212px);
}

.object-storage .object-table .table-header .navigation {
    word-break: break-all;
    width: calc(100% - 408px);
}

.object-storage .object-table .table-header .button-back {
    cursor: pointer;
    background-color: unset;
    border-radius: unset;
}

.object-storage .object-table .table-header .button-back:hover  {
    background-color: #dee2e6;
}

.object-storage .object-table .type-icon {
    background-color: unset;
    border-radius: unset;
}

.object-storage .object-table .avatar-button {
    background-color: unset;
    border-radius: unset;
}

.object-storage .object-table .avatar-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.object-storage .object-table .object-name {
    word-break: break-all;
}

.object-storage .object-table .avatar-button:hover {
    background-color: #dee2e6;
}

.preview-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.preview-dialog .p-dialog-content {
    padding: unset
}

.preview-content {
    overflow: auto;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    height: calc(100% - 2rem - 4px);
}

@keyframes p-progress-spinner-color {

    100%,
    0% {
        stroke: #2196F3;
    }

    40% {
        stroke: #2196F3;
    }

    66% {
        stroke: #2196F3;
    }

    80%,
    90% {
        stroke: #2196F3;
    }
}