.task-details {  
    min-width: 420px;
}

.task-details .container {
    height: 100%;
    overflow: auto;
}

.task-details .panel .p-panel-header {
    color: #0172e2;
    padding: 0px;
    border: none;
    background: none;
    justify-content: left;
} 

.task-details .panel .p-panel-title {
    width: 7.5rem;
}

.task-details .panel .p-panel-content {
    padding: 0px;
    border: none;
}

.task-details ::-webkit-scrollbar {
    width: 8px;
}

.task-details ::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-color: #2196F3;
    background-clip: padding-box;
}


.task-details .resource-link {
    cursor: pointer;
    color: #2196f3;
    text-decoration: none;
}

.task-details .error {
    color: #D32F2F;
}