.connection-details {  
    width: 100%;
}

.connection-details .container {
    height: 100%;
    overflow: auto;
}

.connection-details ::-webkit-scrollbar {
    width: 8px;
}

.connection-details ::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-color: #2196F3;
    background-clip: padding-box;
}


.connection-details .resource-link {
    cursor: pointer;
    color: #2196f3;
    text-decoration: none;
}

.connection-details .show-hide-button {
    height: 1rem !important;
    width: 1.2rem;
}