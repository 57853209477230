.test .p-fieldset {
    height: 100%;
    padding: 0.5rem;
}

.test .p-toggleable-content {
    height: 100%;
}

.test .p-fieldset-content {
    height: 100%;
    padding: 0px;
}

.test .p-toolbar {
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
    border: none;
}

.test .test-container {
    display: flex;
    height: calc(100% - 68px);
}

.test .trees-container {
    height: 100%;
}

.test .tree-container {
    height: 100%;
}

.test .dbt-file-session-container {
    max-height: 100%;
    overflow: auto;
    width: 100%;
}