.bundle-details {  
    min-width: 420px;
}

.bundle-details .container {
    height: 100%;
    overflow: auto;
}

.bundle-details .panel .p-panel-header {
    color: #0172e2;
    padding: 0px;
    border: none;
    background: none;
    justify-content: left;
} 

.bundle-details .panel .p-panel-title {
    width: 7.5rem;
}

.bundle-details .panel .p-panel-content {
    padding: 0px;
    border: none;
}

.bundle-details ::-webkit-scrollbar {
    width: 8px;
}

.bundle-details ::-webkit-scrollbar-thumb {
    border-right: 4px solid rgba(0, 0, 0, 0);
    background-color: #2196F3;
    background-clip: padding-box;
}


.bundle-details .resource-link {
    cursor: pointer;
    color: #2196f3;
    text-decoration: none;
}


.bundle-details .p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}

.bundle-details .p-chip.custom-chip .p-chip-text {
    line-height: 1.2rem;
}

.bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-thead {
    display: none;
}

.bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tbody > tr > td {
    cursor: auto;
}

.bundle-details .p-datatable.p-datatable-bundle-details .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.bundle-details .p-datatable-bundle-details .p-datatable-thead > tr > th > input {
   width: 100%;
}

.bundle-details .p-datatable-bundle-details .p-datatable-thead .p-multiselect {
    width: 100%;
 }

.bundle-details .p-datatable-bundle-details .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-thead > tr > th,
    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .bundle-details .p-datatable.p-datatable-bundle-details .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}