
.flow-job-logs .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.flow-job-logs .filter-container {
    width: 300px;
}

.flow-job-logs .p-datascroller .p-datascroller-content {
    padding: 0.5rem 0
}

.flow-job-logs .log-container {
    padding: 0.15rem 0;
}

.flow-job-logs .log-container .log-timestamp {
    background: #e3f2fd;
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: monospace;
}

.flow-job-logs .log-container .log-payload {
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-family: monospace;
}