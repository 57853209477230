.admin-user .card {
    height: 100%;
}

.admin-user .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.admin-user .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.admin-user .table-header {
    display: flex;
    justify-content: space-between;
}

.admin-user .p-datepicker {
    min-width: 25rem;
}

.admin-user .p-datepicker td {
    font-weight: 400;
}

.admin-user .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-user .resource-link {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
}
.admin-user .resource-link:hover {
    text-decoration: underline;
}

.admin-user .connection-image {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
}

.admin-user .p-chip.plugin-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
}

.admin-user .p-chip.full-access-chip {
    background: var(--green-500);
    color: #ffffff;
}

.admin-user .p-chip.flows-access-chip {
    background: var(--blue-300);
    color: #ffffff;
}

.admin-user .p-chip.transform-access-chip {
    background: var(--blue-400);
    color: #ffffff;
}

.admin-user .p-chip.docs-access-chip {
    background: var(--blue-400);
    color: #ffffff;
}

.admin-user .p-chip.event-scheduler-access-chip {
    background: var(--blue-500);
    color: #ffffff;
}

.admin-user .p-chip.object-storage-access-chip {
    background: var(--blue-600);
    color: #ffffff;
}

.admin-user .p-chip.data-explorer-access-chip {
    background: var(--blue-700);
    color: #ffffff;
}

.admin-user .p-chip.audit-trail-access-chip {
    background: var(--blue-800);
    color: #ffffff;
}

.admin-user .p-chip.object-storage-all-access-chip {
    background: var(--green-500);
    color: #ffffff;
}

.admin-user .p-chip.object-storage-restricted-access-chip {
    background: var(--orange-500);
    color: #ffffff;
}

.admin-user .p-chip.data-explorer-all-access-chip {
    background: var(--green-500);
    color: #ffffff;
}

.admin-user .p-chip.data-explorer-restricted-access-chip {
    background: var(--orange-500);
    color: #ffffff;
}

.admin-user .p-chip.custom-chip .p-chip-text {
    line-height: 1.2rem;
}

.admin-user .p-chip.access-token-chip {
    background: var(--bluegray-300);
    color: #ffffff;
}


.admin-user .button-new-access-token {
    height: 32px;
    border-radius: 16px;
    padding: 0 0.5rem;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: left;
}

.dialog-input {
    width: 100%;
}

.admin-user .p-datatable.p-datatable-users {
    border: 1px solid #dee2e6;
    border-radius: 3px;
    height: 100%;
    overflow: auto;
}

.admin-user .p-datatable.p-datatable-users .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
    border-top: 0px;
}

.admin-user .p-datatable.p-datatable-users .p-paginator {
    border-bottom: 0px;
}

.admin-user .p-datatable.p-datatable-users .p-datatable-wrapper {
    height: calc(100% - 128px);
}

.admin-user .p-datatable.p-datatable-users .p-datatable-thead > tr > th {
    text-align: left;
}

.admin-user .p-datatable.p-datatable-users .p-datatable-tbody > tr > td {
    cursor: auto;
}

.admin-user .p-datatable.p-datatable-users .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.admin-user .p-datatable-users .p-datatable-thead > tr > th > input {
   width: 100%;
}

.admin-user .p-datatable-users .p-datatable-thead .p-multiselect {
    width: 100%;
 }

.admin-user .p-datatable-users .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .admin-user .p-datatable.p-datatable-users .p-datatable-thead > tr > th,
    .admin-user .p-datatable.p-datatable-users .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .admin-user .p-datatable.p-datatable-users .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .admin-user .p-datatable.p-datatable-users .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .admin-user .p-datatable.p-datatable-users .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .admin-user .p-datatable.p-datatable-users .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}