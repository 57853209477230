

.file-session-diff {
    height: 94vh;
    width: 94vw;
    max-height: none;
}

.file-session-diff .file-version {
    margin: 0px;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
    background-color: #f0f0f0;
    padding: 0.5rem;
}

.file-session-diff .file-content {
    padding: 0.5rem;
}

.file-session-diff .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.file-session-diff .p-tabview .p-tabview-panels {
    padding: 0px;
}

.file-session-diff .p-grid {
    margin: 0.5rem -0.5rem 0.5rem -0.5rem !important;
}

.file-session-diff .p-grid .p-col {
    margin: 0.5rem;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding: 0px;
}
