.external-app {}

.external-app .app-stopped {
    filter: grayscale(100%) opacity(0.5);
}

.external-app .app-name-container {
    width: 100%;
}

.external-app .app-name {
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow: hidden;
}

.external-app .app-item {
    cursor: pointer;
    width: calc(100% - 1.5rem);
}

.external-app .option-button {
    height: 1.5rem;
    width: 1.5rem;
    background-color: unset;
    border-radius: unset;
}

.external-app .url-img {
    background-color: #dee2e6;
    border-radius: 3px;
}

.external-app .url-img>img {
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: auto;
    height: auto;
}

.external-app .url-img>.p-progress-spinner-svg {
    height: 24px;
    width: 24px;
}