
.option-button {
    height: 1.5rem;
    width: 1.5rem;
    background-color: unset;
    border-radius: unset;
}

.job-exec-dialog {
    height: unset;
}

.job-exec-dialog .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.job-exec-dialog .select-command {
    width: 136px;
    border-radius: 4px 0px 0px 4px;
}

.job-exec-dialog .exec-container {
    width: 100%;
    align-items: flex-start;
}

.job-exec-dialog .exec-container .button-test {
    min-width: 86px;
}
 
.job-exec-dialog .exec-container .button-build-run {
     min-width: 86px;
}

.job-exec-dialog .exec-form {
    width: calc(100% - 223px - 1rem)
}

.job-exec-dialog .exec-form .args-input {
    width: 100%;
    border-radius: 0px 4px 4px 0px;
}

.job-exec-dialog .command-output {
    border: 1px solid #dee2e6;
    border-radius: 3px;
    display: block;
    padding: 12px;
    min-height: 240px;
    height: calc(100% - 62px);
    overflow: auto;
    background-color: #212121;
    padding: 1.25rem;
    font-family: monospace;
    font-size: 0.9rem;
    font-weight: normal;
    color: #fff;
}