
.de-search-container {
    height: 100%;
    width: 300px;
    transition: all 0.5s ease 0s;
}

.collapsed-de-search-container {
    width: calc(32px + 2rem);
    visibility: hidden;
}

.de-search-container .button-back {
    cursor: pointer;
    background-color: unset;
    border-radius: unset;
    float: right;
    visibility: visible;
}

.de-search-container .button-back:hover  {
    background-color: #dee2e6;
}

.de-search-container .de-search-form-container {
    height: calc(100% - 34px - 2rem);
    width: 100%;
}

.de-search-container .de-search-form-container .de-search-form {
    width: 100%;
    margin-bottom: 1rem;
    font-size: small;
    height: 100%;
    overflow-y: auto;
    overflow-x: clip;
}

.de-search-container .de-search-form-container .de-search-form>.p-field>div {
    display: flex;
    float: left;
    flex-flow: wrap;
    width: 100%;
    /*margin-left: -0.5rem;
    margin-right: -0.5rem;*/
}

.de-search-container .de-search-form-container .de-search-form .p-field>div>h3 {
    width: 100%;
    margin-top: 0px;
    /*margin: -1rem 0.5rem 1rem 0.5rem;*/
}

.de-search-container .de-search-form-container .de-search-form .p-field>div>h4 {
    width: 100%;
    margin-top: 0px;
    /*margin: 0 0.5rem 1rem 0.5rem;*/
}

.de-search-container .de-search-form-container .de-search-form .p-field>div .property-wrapper {
    width: 300px;
   /* margin: 0.5rem;*/
}

.de-search-container .de-search-form-container .de-search-form .p-button {
    display: block;
    margin: auto;
    width: unset;
}
