.form-login .card {
    background-color: #fff;
    min-width: 350px;
}
.form-login .card form {
    margin-top: 2rem;
}
.form-login .card .p-field {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-login .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-login .card {
        width: 100%;
        min-width: 0;
    }
}