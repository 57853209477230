.ace-editor {
    border-top: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.ace-editor-header {
    height: 34px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.ace-editor-header .filename {
    margin: 0px 1rem;
    font-weight: bold;
    flex-grow: 1;
}

.ace-editor-header .avatar-button {
    background-color: unset;
    border-radius: unset;
    margin-right: 0.5rem;
}

.ace-editor-header .avatar-button.p-avatar {
    height: 1.5rem;
    width: 1.5rem;
}

.ace-editor-header .avatar-button:hover {
    background-color: #dee2e6;
}