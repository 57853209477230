
.service-control-panel {
    
}


.service-control-panel .resource-link {
    cursor: pointer;
    color: #2196f3;
}

.service-control-panel .endpoints {
    display: flex;
    flex-flow: wrap;
}

.service-control-panel .external-link {
    cursor: pointer;
    color: #2196f3;
    word-break: break-all;
    width: 100%;
    text-decoration: none;
}
.service-control-panel .external-link:hover {
    text-decoration: underline;
}

.service-control-panel .status-blue {
    color: var(--blue-500);
}

.service-control-panel .status-orange {
    color: var(--orange-500);
}

.service-control-panel .status-red {
    color: var(--red-500);
}

.service-control-panel .status-grey {
    color: var(--gray-900);
}

.service-control-panel .status-green {
    color: var(--green-500);
}


.service-control-panel .payload {
    white-space: pre-line;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    display: block;
    padding: 0.25rem;
    font-family: monospace;
    font-size: 0.9rem;
    font-weight: normal;
}

.service-control-panel .error {
    color: #D32F2F;
}