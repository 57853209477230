.App {
    height: 100vh;
}

.App .p-grid {
    height: 100%;
}

.app-container {
    height: 100vh;
    /*background-image: url('./images/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;*/
    overflow: auto;
}

.app-container>div {
    height: 100%; 
}

.p-grid {
    margin: 0px !important;
}

.title-color {
    color: #1a78c2;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #2196F3;
    background-clip: padding-box;
}