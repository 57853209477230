.exec-task .title {
    font-size: x-large;
    font-weight: 400;
    margin: 0.5rem;
}

.exec-task .container {
    height: calc(100% - 16.5rem);
    overflow: auto;
}

.exec-task .xxl-avatar {
    background-color: #e3f2fd;
    width: 8rem;
    height: 8rem;
}

.exec-task .xxl-avatar>.p-avatar-icon {
    color: #2196F3;
    font-size: 4rem;
}


.exec-task .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    border-radius: 25%;
}


@media screen and (max-width: 640px) {
    .exec-task .p-steps {
        height: 350px;
    }
    .exec-task .p-steps > ul {
        flex-direction: column;
        height: 100%;
    }
    .exec-task .p-steps > ul .p-steps-item {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;
    }
    .exec-task .p-steps > ul .p-steps-item:before {
        position: static;
        left: auto;
        top: auto;
        margin-top: 0;
        border-left: 1px solid var(--surface-d);
        border-top: 0 none;
        width: auto;
        height: 100%;
        margin-left: 1rem;
    }
    .exec-task .p-steps > ul .p-steps-item .p-menuitem-link {
        flex-direction: row;
        overflow: visible;
    }
    .exec-task .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
        margin: 0 .5rem 0;
    }
    .exec-task .p-steps > ul .p-steps-item:last-child {
        flex-grow: 0;
    }
    .exec-task .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
        padding: 0;
    }
    .exec-task .p-steps > ul .p-steps-item:last-child:before {
        display: none;
    }
}