.bundle-runs-table .p-datatable {
    margin: 0px -1.75rem;
}

.bundle-runs-table .p-paginator {
    border: none;
    padding-bottom: 0px;
}

.bundle-runs-table .p-paginator .p-paginator-current {
    margin-left: auto;
}

.bundle-runs-table .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.bundle-runs-table .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.bundle-runs-table .table-header {
    display: flex;
    justify-content: space-between;
}

.bundle-runs-table .p-datepicker {
    min-width: 25rem;
}

.bundle-runs-table .p-datepicker td {
    font-weight: 400;
}

.bundle-runs-table .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bundle-runs-table .resource-link {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
    text-decoration: none;
}
.bundle-runs-table .resource-link:hover {
    text-decoration: underline;
}

.bundle-runs-table .connection-image {
    cursor: pointer;
    color: #2196f3;
    width: fit-content;
}

.bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.bundle-runs-table .p-datatable.p-datatable-jobs .p-paginator {
    padding: 1rem 1rem 0px 1rem;
}

.bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-thead > tr > th {
    text-align: left;
}

.bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tbody > tr > td {
    cursor: auto;
}

.bundle-runs-table .p-datatable.p-datatable-jobs .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.bundle-runs-table .p-datatable-jobs .p-datatable-thead > tr > th > input {
   width: 100%;
}

.bundle-runs-table .p-datatable-jobs .p-datatable-thead .p-multiselect {
    width: 100%;
 }

.bundle-runs-table .p-datatable-jobs .p-datatable-tbody > tr > td .p-column-name {
    display: none;
}

@media screen and (max-width: 960px) {
    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-thead > tr > th,
    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }

    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tbody > tr > td .p-column-name {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .bundle-runs-table .p-datatable.p-datatable-jobs .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}