.connectors-store {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.connectors-store .dataview {
    margin-top: -0.5rem;
}

.connectors-store .table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    font-size: x-large;
    font-weight: 400;
}

.connectors-store .dataview>.p-dataview-content {
    height: 18.5rem;
}

.connectors-store .connector-name {
    font-size: 1.2rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.connectors-store .connector-grid-item {
    margin: .5rem;
    padding: .5rem;
    height: 8rem;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}

.connectors-store .connector-grid-item img {
    width: 3rem;
    height: 3rem;
    margin: .5rem 0;
}

.connectors-store .connector-grid-item .incompatible-connector {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.connectors-store .connector-grid-item .connector-grid-item-content {
    text-align: center;
}