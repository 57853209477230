.connections-store {
    width: 100%;
    max-width: 800px;
}

.connections-store .dataview>.p-dataview-content {
    height: 28rem;
}

.connections-store .table-header {
    display: flex;
    justify-content: center;
}

.connections-store .connection-name {
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.connections-store .connection-grid-item {
    margin: .5rem;
    padding: .5rem;
    height: 8rem;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    cursor: pointer;
}

.connections-store .connection-grid-item img {
    width: 3rem;
    height: 3rem;
    margin: .5rem 0;
}

.connections-store .connection-grid-item .connection-grid-item-content {
    text-align: center;
}