

.git-job-exec {
    height: 100%;
}

.git-job-exec .p-dialog-header {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.git-job-exec .select-project {
    width: 176px;
    border-radius: 4px 0px 0px 4px;
}

.git-job-exec .exec-container {
    width: 100%;
    align-items: flex-start;
}

.git-job-exec .exec-container .button-test {
    min-width: 86px;
 }
 
 .git-job-exec .exec-container .button-build-run {
     min-width: 148px;
 }

.git-job-exec .exec-form {
    width: calc(100% - 263px - 1rem)
}

.git-job-exec .exec-form .cmd-input {
    width: 100%;
    border-radius: 0px 4px 4px 0px;
}

.git-job-exec .command-output {
    border: 1px solid #dee2e6;
    border-radius: 3px;
    display: block;
    padding: 12px;
    min-height: 240px;
    height: calc(100% - 62px);
    overflow: auto;
    background-color: #212121;
    padding: 1.25rem;
    font-family: monospace;
    font-size: 0.9rem;
    font-weight: normal;
    color: #fff;
}